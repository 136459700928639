html {
  font-family: helvetica
}

body {
  margin: 0;
  background-color: rgb(77, 67, 67)
}

p {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  color: white;
}

.nav-div ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0;
}

.nav-div ul li {
  padding-right: 25px;
}

li {
  list-style: none;
}

.title-div span {
  padding: 25px;
  display: block;
}

.title-div {
  height: 100%;
}

ul {
  margin: 0
}

.footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.nz-img {
  margin: auto;
  display: block;
}

.Gisborne {
  position: relative;
  bottom: 543px;
  left: 616px;
}

.Gisborne:hover {
  transform: scale(1.2);
}

.map-div, .pin-div {
  display: inline-block;
  width: 768px;
  height: 1024px;
  margin: 0 auto;
}

.pin-div {
  position: absolute;
}

.test {
  margin: auto
}

img {
  transform: rotate(180 deg);
}

.wind-arrow {
  margin: auto;
  display: block;
  filter: sepia(1);
}

.Gisborne-div, .Wellington-div, .Auckland-div, .Hastings-div, .Kaitaia-div, .Taupo-div, .Hamilton-div {
  position: absolute;
  width: auto;
  height: auto;
  display: inline-block;
  min-width: 215px;
}

.container {
  width: 768px;
  display: flex;
  margin: 0 auto;
}

.button-div {
  display: flex;
  justify-content: center;
}

.mouseover-div {
  width: 32px;
}

.Gisborne-div .mouseover-div {
  position: absolute;
  z-index: 1;
}

a {
  color: white;
  text-decoration: none;
}

li:hover {
  color: red;
  cursor: pointer
}

button {
  font-size: 20px;
  border-radius: 10px;
  padding: 10px 40px;
  cursor: pointer;
  border: 1px solid grey
}

button:hover {
  border: 1px solid red;
}

button:disabled {
  font-size: 20px;
  border-radius: 10px;
  padding: 10px 40px;
}

button:disabled:hover {
  border: 1px solid grey;
  cursor:not-allowed
}

.button-div {
  display: flex

}

.fetch-div {
  display: block;
  color: white;
}

.footer {
  color: white
}

svg {
  animation: popup 0.8s;
}

svg:hover {
  transform: scale(1.1);
}

@keyframes popup {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.4);
  }
  60%{
    transform: scale(1.1);
  }
  70%{
    transform: scale(1.2);
  }
  80%{
    transform: scale(1);
  }
  90%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
}