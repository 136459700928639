.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  visibility: hidden;
  padding: 5px;
  border-radius: 5px;
  width: 150px;
  border: 1px solid white;
  z-index: 1;
}
.tooltip-arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent;
}
